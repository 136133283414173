<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Communal Repairs</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Communal Repairs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.repairDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Repair</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-sbpm-communalrepairs-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archive Communal Repairs</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="tableHeaders"
      :items="repairs"
      class="mt-4"
      no-data-text="No repairs found"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="green lighten-4 green--text"
              class="mr-2"
              :to="{
                name: 'module-sbpm-communalrepairs-individual',
                params: { repairId: item.id },
              }"
            >
              <v-icon x-small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="red lighten-4 red--text"
              v-on="on"
              @click="openDelete(item)"
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Archive</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <repair-dialog ref="repairDialog" />
    <v-dialog v-model="deleteRepair.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Repair</v-card-title>
        <v-card-text
          >Are you sure you want to archive this repair form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteRepair.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RepairDialog from "./components/RepairDialog.vue";
export default {
  components: { RepairDialog },

  data() {
    return {
      deleteRepair: {
        dialog: false,
        repair: {},
        loading: false,
      },
      groups: [],
      searchTerm: "",
      tableHeaders: [
        { text: "Property", value: "property.property_name" },
        { text: "Works", value: "short_description" },
        { text: "Contractor", value: "contractor" },
        { text: "Quote", value: "quote" },
        { text: "Share Type", value: "share" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    repairs() {
      let repairs = this.$store.getters["sbpm/repairsStore/all"];

      return repairs;
    },
  },

  methods: {
    openDelete(repair) {
      this.deleteRepair.repair = repair;
      this.deleteRepair.dialog = true;
    },

    resetDelete() {
      this.deleteRepair.dialog = false;
      this.deleteRepair.repair = {};
      this.deleteRepair.loading = false;
    },

    saveDelete() {
      this.deleteRepair.loading = true;

      this.$store
        .dispatch("sbpm/repairsStore/deleteRepair", {
          appId: this.$route.params.id,
          repairId: this.deleteRepair.repair.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteRepair.loading = false;
        });
    },
  },
};
</script>
